import {NBAStatusBadge} from '@/components/NBAStatusBadge/NBAStatusBadge'
import {ColumnDef} from '@tanstack/react-table'
import {CSSProperties} from 'react'
import {IntlFormatters} from 'react-intl'
import {DataTableColumnHeader} from '../../../shared-components/DataTable/DataTableColumnHeader'
import {NBAListItem} from '../../../types/NBAs'
import {CloneCell, DateCell, PeriodCell, NBADescriptionCell, NBANameCell, ReportCell} from './CustomCells'
import {TaskType} from '@/types/TaskSchema'

export type CustomColumnMeta = {
  styles?: CSSProperties
}

export const getTableColumns = (
  formatMessage: IntlFormatters['formatMessage']
): ColumnDef<NBAListItem, CustomColumnMeta>[] => {
  return [
    {
      accessorKey: 'name',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NBA_LIST.TABLE_NAME_COLUMN'})} />
      ),
      cell: ({row}) => <NBANameCell id={row.original.id} name={row.getValue('name')} />,
      meta: {
        styles: {
          minWidth: 100,
          maxWidth: 100,
          width: '22%',
        },
      },
    },
    {
      accessorKey: 'duration',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NBA_LIST.TABLE_DURATION'})} />
      ),
      cell: ({row}) => <PeriodCell value={{start: row.original.startDate, end: row.original.endDate}} />,
      meta: {
        styles: {
          minWidth: 200,
          width: '20%',
        },
      },
      sortDescFirst: false,
    },
    {
      accessorKey: 'displayDuration',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NBA_LIST.TABLE_DISPLAY_DURATION'})} />
      ),
      cell: ({row}) => <PeriodCell value={{start: row.original.displayStartDate, end: row.original.displayEndDate}} />,
      meta: {
        styles: {
          minWidth: 200,
          width: '20%',
        },
      },
      sortDescFirst: false,
    },
    {
      accessorKey: 'description',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NBA_LIST.TABLE_DESCR_COLUMN'})} />
      ),
      enableSorting: false,
      cell: ({row}) => <NBADescriptionCell description={row.getValue('description')} />,
      meta: {
        styles: {
          minWidth: 150,
          width: '30%',
        },
      },
    },
    {
      accessorKey: 'status',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NBA_LIST.TABLE_STATUS_COLUMN'})} />
      ),
      meta: {
        styles: {
          minWidth: 100,
          width: '15%',
        },
      },
      cell: ({row}) => <NBAStatusBadge status={row.getValue('status')} />,
    },
    {
      accessorKey: 'publishedDate',
      header: ({column}) => (
        <DataTableColumnHeader {...column} title={formatMessage({id: 'NBA_LIST.TABLE_PUBLISHED_AT_COLUMN'})} />
      ),
      cell: ({row}) => <DateCell value={row.getValue('publishedDate')} />,
      meta: {
        styles: {
          minWidth: 150,
        },
      },
      sortDescFirst: false,
    },
    {
      accessorKey: 'clone',
      header: ({column}) => <DataTableColumnHeader {...column} title="" />,
      cell: ({row}) => <CloneCell value={row.getValue('status')} />,
      meta: {
        styles: {
          minWidth: 80,
          width: 80,
        },
      },
    },
    {
      accessorKey: 'route-to-report',
      header: ({column}) => <DataTableColumnHeader {...column} title="" />,
      cell: ({row}) => (
        <ReportCell
          nbaId={row.original.id}
          nbaName={row.original.name}
          value={row.getValue('status')}
          tasks={row.original.tasks as Array<TaskType>}
        />
      ),
      meta: {
        styles: {
          minWidth: 240,
        },
      },
    },
  ]
}
