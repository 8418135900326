import {getCountries} from '@/helpers/envConfig'
import {getNBAsService} from '../services/nbasService'
import {GetNBAsResponse} from '../types/NBAs'
import {store} from '@/store'

export type GetNBAsUseCaseParams = {
  pageNumber?: number
  pageSize?: number
  sort?: string
  query?: string
}

export const DEFAULT_SORT = 'name,asc'

export const DEFAULT_PAGE_SIZE = 10

export async function getNBAsUseCase(params?: GetNBAsUseCaseParams, signal?: AbortSignal): Promise<GetNBAsResponse> {
  const {pageNumber = 0, pageSize = DEFAULT_PAGE_SIZE, sort = DEFAULT_SORT, query = ''} = params || {}
  const countryCode = store.getState().common.country.code || getCountries()[0]
  return getNBAsService({pageNumber, pageSize, sort, query, countryCode}, signal)
}
